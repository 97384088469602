.calendar-main {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

.calendars-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 50px;
  min-width: 100vw;
  min-height: 100vh;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  grid-gap: 0px;
  grid-auto-rows: minmax(50px, auto);
}

.booking-hover {
  border-radius: 3px;
  padding: 10px;
  background-color: white;
  min-height: 200px;
}

section {
  box-sizing: border-box;
  border: solid 1px yellow;
  background: green;
  grid-column: 15;
  grid-row: 15;
}
section:hover {
  background: blue;
}
section::after {
  background: pink;
}
.staff-view-times {
  padding-bottom: 10px;
}
.staff-view {
  overflow-x: scroll;
}
.staff-view-time-padding {
  padding-top: 0.5vh;
}
.staff-col {
  min-width: 70px;
}
.red {
  background: red;
}
.blue {
  background: blue;
}
.yellow {
  background: yellow;
}
.pink {
  background: pink;
}
.grey {
  background: grey;
}
.text-styling {
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
}
.text-styling-staff {
  text-align: center;
  vertical-align: middle;

  -ms-transform: rotate(-25deg); /* IE 9 */
  -webkit-transform: rotate(-25deg); /* Safari 3-8 */
  transform: rotate(-25deg);
  font-size: 1vh;
  white-space: nowrap;

  width: 50px;
}
.text-styling-staff p {
  margin: 0px;
  max-width: 50px;
}
.text-styling-staff p:first-child {
  padding-top: 11px;
}
.staff-column-header {
  padding-left: 15px;
  padding-top: 0.5vh;
  height: 5vh;
}
.calendar-top-bar {
  background-color: #f7f7f7;
  padding: 5px;
  min-height: 60px;
}
.calendar-top-date {
  padding: 7px 0px;
}
.calendar-tabs {
  background-color: #efeaea;
  min-height: 60px;
}
.calendar-search {
  max-width: 300px;
  background-color: #efeaea;
  border: none !important;
  box-sizing: border-box;
}
.calendar-search:hover {
  background-color: inherit !important;
}
.text-box {
  border-top: none !important;
  border-bottom: none !important;
}
.column {
  box-sizing: border-box;
  width: 7vw;
  overflow: hidden;
}
.slot {
  border-left: solid 1px #dadfea;
  border-right: solid 1px #dadfea;
  border-top: 1px white;
  border-bottom: 1px white;
  overflow: hidden;
  background: white;
}
.slot.half-hour {
  border-top: dashed 1px #dadfea;
}
.slot.hour {
  border-top: solid 1px #dadfea;
}

.slot.reserved {
  border-top: solid 1px #dadfea;
  opacity: 0.5;
  padding-top: 1px;
  background: #909090 !important;
}

.slot.booked {
  border-top: solid 1px #dadfea;
  background: #efeaea;
  font-size: 12px;
}
.slot.new-booking {
  border-top: solid 1px #a60c46;
  padding-top: 1px;
  background: #a60c46 !important;
  box-shadow: 0 0 7px #ef3c52 !important;
  transition: box-shadow 200ms ease-out;
  z-index: 4;
}
/*
.slot:hover{
  background: purple;
}*/
.time-slot {
  padding-left: 5px;
  margin-top: -1rem;
  color: #aeb8ca;
}
.height100 {
  height: 100%;
}

.collection-tab {
  max-width: 200px;
  background-color: #efeaea;
  border: 1px solid #e0dede;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
}
.collection-tab.daily {
  max-width: 300px !important;
}
.collection-tab:hover {
  background-color: #f4f4f4;
}
.selected-tab {
  background-color: white !important;
  border-bottom: none !important;
}
.collection-column {
  border-right: 1px solid #4a4a4a;
  border-bottom: 1px solid #dadfea;
}
.collection-column-day-view {
  border-bottom: 1px solid #dadfea;
}
.days-7 {
  width: 14.3%;
}
.days-6 {
  width: 28.6%;
}
.days-5 {
  width: 42.9%;
}
.days-4 {
  width: 57.2%;
}
.days-3 {
  width: 71.5%;
}
.days-2 {
  width: 85.8%;
}
.days-1 {
  width: 100%;
}
.calendar-date {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
}
.date-field {
  width: 200px;
}
.date-field-week {
  width: 350px;
}
/*
.arrow {
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.arrow:hover {
  font-size: 30px;
  margin-top: -5px !important;
  padding-top: 5px;
  margin-bottom: -5px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}*/

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 50px 30px 50px;
}
.modal-body div {
  padding: 10px 0px 10px 0px;
}
.modal-date-time-location {
  margin-bottom: 0px;
  font-weight: bold;
}
.modal-date-time-location p {
  margin-bottom: 0px;
}

.close {
  color: red !important;
  opacity: 1 !important;
}
.modal-logo {
  max-width: 60px !important;
  margin: 10px 50px;
}
.modal-logo-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.form-input-extra-styling {
  border-radius: 3px !important;
  background-color: #eeeeee !important;
  margin-bottom: 10px;
  color: #b5b4b5;
  text-align: left;
}
.form-input-extra-styling.no-border {
  border: none !important;
}

.confirm-button {
  background-color: #a60c46;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px 2px;
  border-radius: 3px;
  width: 200px;
}
.modal-confirm-button {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.new-bookig-modal {
  position: absolute;

  overflow: auto;
  overflow-y: auto;
}
.new-bookig-modal div {
  padding: 2px 5px;
}
.new-bookig-modal p {
  padding: 5px;
}
.new-bookig-modal.right {
  left: -45vw;
}
.new-bookig-modal.left {
  right: -45vw;
}
.new-bookig-modal.top {
  top: 55vh;
}
.new-bookig-modal.bottom {
  top: 0vh;
}
.new-booking-modal-checkbox {
  padding-top: 14px !important;
}

.client-select div {
  padding: 0px;
}
.control-label {
  font-weight: 500;
  padding: 0px 5px;
}

.calendar-view {
  margin: 0px 10px 10px 0px;

  border-radius: 3px;
}
.calendar-view-staff {
  margin: 0px;
  overflow-x: scroll;

  border-radius: 3px;
}
.booking-information {
  width: auto;
  min-width: 300px;
  height: auto;
  border: 2px solid #a60c46;
  background-color: #a60c46;
  display: none;
  position: absolute;
  z-index: 5;
  border-radius: 3px;
  padding: 5px;
  box-shadow: 5px 10px 18px #333333;
  font-size: 16px;
  font-weight: 100;
}
.hover-booking-icon {
  width: 20px;
  height: 20px;
  margin: 0px 6px 6px;
}
.client-position {
  font-size: 14px;
}
.booking-information.display {
  display: block;
}

.booking-information-header {
  background-color: #a60c46;
  color: white;
  margin: -5px -5px 0px -5px;
  padding: 5px;
}

.sales-session-select {
  max-width: 300px;
  padding-left: 5px;
}

.date-time-box {
  background-color: #f7f7f7;
  margin-bottom: 20px;
}
.date-time-box p {
  margin-bottom: 0px;
  padding-left: 10px;
}
.edit-booking-header {
  padding: 15px;
  font-size: 20px;
  border-bottom: 2px solid #f7f7f7;
}
.client-information {
  border: 2px solid #f7f7f7;
  border-radius: 3px;
  margin-bottom: 10px;
}

.client-information div {
  width: 45%;
  margin: 5px;
  background-color: #f7f7f7;
}
.client-select span:first-child {
  margin-bottom: 0px !important;
}
.client-select span {
  margin-bottom: 4px !important;
}
.booking-week-view {
  font-size: 10px;
}
.booking-week-view p {
  padding: 1px;
}
