#main {
  margin: 0 !important;
  padding: 0 !important;
  font-family: avenir;
}

body {
  font-family: avenir;
}

@font-face {
  font-family: avenir;
  font-weight: 400;
  font-style: normal;
  src: url("./AvenirNext-Medium.ttf");
}
@font-face {
  font-family: avenir;
  font-weight: 300;
  font-style: normal;
  src: url("./AvenirNext-UltraLight.ttf");
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col.start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.flex-col.end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.flex-col.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex-col.space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.flex-col.space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-row.start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.flex-row.end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.flex-row.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.flex-row.space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.flex-row.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.large-margin-horizontal {
  margin: 0px 20px;
}
.large-margin-vertical {
  margin: 20px 0px;
}
.large-padding-horizontal {
  padding: 0px 20px;
}
.medium-margin-vertical {
  margin: 10px 0px;
}
.small-margin-vertical {
  margin: 5px 0px;
}
.medium-margin-horizontal {
  margin: 0px 10px;
}
.medium-padding-horizontal {
  padding: 0px 10px;
}
.medium-padding-vertical {
  padding: 10px 0px;
}

.large-padding {
  padding: 20px;
}
.medium-padding {
  padding: 10px;
}
.small-padding {
  padding: 5px;
}
.large-margin {
  margin: 20px;
}
.medium-margin {
  margin: 10px;
}
.small-margin {
  margin: 5px;
}
.no-padding {
  padding: 0px !important;
}

.full-height {
  height: 100vh;
}

.disabled-button {
  opacity: 0.5;
}

.sidemenu {
  position: fixed;
  height: 100%;
  width: 200px;
}

.container-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 200px;
  padding: 15px;
  margin-top: 50px;
  width: 100%;
  height: 100vh - 50px;
  box-sizing: border-box;
}

thead.full-screen {
  background-color: #f5f5f5;
  border-bottom: 3px solid #d8d8d8;
}
.select-medium {
  border: none;
  color: white;
  padding: 10px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 0px 2px;
  cursor: pointer;
  border-radius: 3px;
  min-width: 150px;
  width: auto;
}
.button-allign-text {
  padding: 10px 18px;
  margin: 4px 2px;
}

.button {
  border: none;
  color: white;
  padding: 10px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;
  min-width: 150px;
  width: auto;
}

.button.red {
  background-color: #ef3c52;
}
.button.burgundy {
  background-color: #a60c46;
}
.button.green {
  background-color: #45cea2;
}
.button.blue {
  background-color: #45b6ce;
}
.font-size-26 {
  font-size: 26px;
}
.font-size-20 {
  font-size: 20px;
}
.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.6%;
}
.col-10 {
  width: 83.3%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.6%;
}
.col-7 {
  width: 58.3%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.6%;
}
.col-4 {
  width: 33.3%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.6%;
}
.col-1 {
  width: 8.3%;
}

.f-1 {
  flex: 1;
}
.f-2 {
  flex: 2;
}
.f-3 {
  flex: 3;
}
.f-4 {
  flex: 4;
}
.f-5 {
  flex: 5;
}
.f-6 {
  flex: 6;
}
.f-7 {
  flex: 7;
}
.f-8 {
  flex: 8;
}
.f-9 {
  flex: 9;
}
.f-10 {
  flex: 10;
}
.f-11 {
  flex: 11;
}
.f-12 {
  flex: 12;
}
.f-13 {
  flex: 13;
}
.f-14 {
  flex: 14;
}
.f-15 {
  flex: 15;
}
.f-16 {
  flex: 16;
}
.f-17 {
  flex: 17;
}
.f-18 {
  flex: 18;
}
.f-19 {
  flex: 19;
}
.f-20 {
  flex: 20;
}
.f-24 {
  flex: 24;
}

.loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-circle-small {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;

  max-width: 200px;
  max-height: 200px;
}
.react-toggle {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 55px;
  height: 25px;
  padding: 0;
  border-radius: 30px;
  background-color: lightgrey;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover .react-toggle-track {
  background-color: lightgrey;
}

.react-toggle--checked .react-toggle-track {
  background-color: #26c3bb;
}

.react-toggle.react-toggle--checked:hover .react-toggle-track {
  background-color: #26c3bb;
}

.react-toggle-track-check {
  display: none;
  position: absolute;
  width: 7px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  display: none;
  position: absolute;
  width: 5px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 23px;
  height: 23px;
  border: 1px solid #26c3bb;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.1s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 30px;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #00bcb3;
  -moz-box-shadow: 0px 0px 3px 2px #00bcb3;
  box-shadow: 0px 0px 2px 3px #00bcb3;
}

.react-toggle:active .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #00bcb3;
  -moz-box-shadow: 0px 0px 5px 5px #00bcb3;
  box-shadow: 0px 0px 5px 5px #00bcb3;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown__content {
  display: none;
  position: absolute;
  right: 0px;
  z-index: 11;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12);
}

.dropdown--active .dropdown__content {
  display: block;
}

.ReactModal__Overlay .ReactModal__Overlay--after-open {
  z-index: 10;
}

.ReactModal__Content {
  opacity: 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 150ms;
}

.ReactModal__Content--before-close {
  opacity: 0;
}

.Toastify__toast {
  font-family: "Open Sans", "Avenir Next";
  font-size: 16px;
}

.Toastify__toast--success {
  background-color: rgb(69, 206, 162) !important;
}

.def-toast {
  background-color: #ffcab1 !important;
  color: #000 !important;
}
.def-toast .Toastify__toast-body {
  margin: 24px;
}

.Toastify__toast-container.custom-toast-container {
  min-width: 320px;
  width: auto;
  max-width: 400px;
}

.checkboxLabel {
  margin-left: 0.5rem;
}
.checkboxLabel:first-of-type {
  margin-left: 0;
}


/* table styles */

.Table {
  width: 100%;
  margin-top: 15px;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}

.headerRow,
.evenRow,
.oddRow,
.disabledRow
{
  border-bottom: 1px solid #e0e0e0;
  outline: none;
}

.oddRow {
  background-color: #fafafa;
}

.disabledRow {
  background-color: #f0f0f0;
}

.headerColumn {
  text-transform: none;
}
.exampleColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactVirtualized__Table__row {
  cursor: pointer;
}

.ReactVirtualized__Table__row:hover {
  background-color: #eee;
}

.Buyers-Table {
  font-family: avenir;
  font-size: 14px;
  color: #000000;
}

.ReactVirtualized__Table__headerRow {
  border-bottom: 4px solid #d8d8d8;
}

.ReactVirtualized__Table__row,
.ReactVirtualized__Grid__innerScrollContainer,
.ReactVirtualized__Table__rowColumn.visible {
  overflow: visible !important;
}

.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row {
  padding-left: 20px;
  padding-right: 20px !important;
}

.ReactVirtualized__Table__row.disabled {
  cursor: initial;
}

.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 0;
}

.ReactVirtualized__Table__headerColumn:first-of-type {
  margin-left: 0;
}

.Buyers-Table__grid {
  outline: none;
}

.Buyers-Table__header {

}

.deleteButton {
  border-radius: 50%;
  min-width: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  height: 40px;
  transition: background-color 0.6s ease;
}

.deleteButton svg {
  pointer-events: none;
  transition: fill 0.6s ease;
}

.deleteButton:hover {
  background: rgba(0, 0, 0, 0.5);
}

.deleteButton:hover svg {
  fill: #fff !important;
}

.modaresa-ui-helptooltip {
  line-height: 1.2;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
