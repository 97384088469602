/*
table {
  border-spacing: 0;
}

tbody,
thead tr {
  display: block;
}*/
/*
.sales-sessions tr {
  padding: 0px 20px;
}*/
.sales-sessions td {
  padding: 0px 20px;
}
.sales-sessions td {
  padding: 20px 10px 0px 10px !important;
  height: 80px;
}
.sales-sessions th {
  padding: 20px 10px 0px 10px !important;
  height: 60px;
  border-bottom: none !important;
}
.sales-session-table-header {
  height: 60px;
}
tbody {
  max-height: 59vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.allocated tbody {
  max-height: calc(100vh - 325px);
}
.selected-clients tbody {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.allocate-staff-table tbody {
  max-height: 68vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.allocate-staff-table table {
  margin-bottom: 0px;
}
.home-footer {
  margin: 0px -15px -15px -15px;
  padding: 15px 50px;
  background-color: #f1f1f1;
  max-height: 70px;
}
.invited {
  background-color: #45b6ce;
  padding: 3px 10px;
  border-radius: 3px;
}
.booked {
  background-color: #45cea2;
  padding: 3px 10px;
  border-radius: 3px;
}
.invited-opened {
  background-color: orange;
  padding: 3px 10px;
  border-radius: 3px;
}
.not-invited {
  background-color: #c2c2c2;
  padding: 3px 10px;
  border-radius: 3px;
}
/*
tbody td, thead th {
    width: 10%;
}

thead th:last-child {
    width: 10% - 16px;
}
thead th:first-child {
    width: 20px;
}

tbody td:last-child {
    width: 10% - 16px;
}
tbody td:first-child {
    width: 20px;
}

td.email{
  width: 20%;
}
th.email{
  width: 20%;
}

td.position{
  width: 10%;
}
th.position: {
  width: 10%;
}
*/

.selected-clients {
  margin: 20px 0px;

  width: 100%;
  padding-right: 5px;
}

.unselected-clients {
  margin-top: 20px;

  width: 100%;
}

.green-border {
  border: 3px solid #45cea2;
}
.red-border {
  border: 3px solid #ef3c52;
}
.blue-border {
  border: 3px solid #45b6ce;
}

.review {
  padding: 30px 15px;
}
.review-element {
  margin: 10px 0px;
}
.review-header {
  width: 100%;
  border-bottom: 4px solid #f7f7f7;
  margin-bottom: 5px;
}

.review-body {
  width: 80%;
  background-color: #f7f7f7;
}
.review-line {
  padding: 10px 10px;
  margin-right: 50px;
}

.loading-circle {
  max-width: 300px;
}
.loading-circle-container {
  margin-left: 45vw;
  margin-right: auto;
}

.session-animation-1 {
  animation: appear1 100ms ease-in;
}
.session-animation-2 {
  animation: appear2 100ms ease-in;
}
@keyframes appear1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1 !important;
  }
}

@keyframes appear2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1 !important;
  }
}
.sales-session-content-extra {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -15px !important;
}
.container-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 200px;
  padding: 0px !important;
  margin-top: 50px;
  width: 100%;
  height: 100vh - 50px;
  box-sizing: border-box;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.form-group {
  margin-left: 0px !important;
}
/***********************STYLING FROM SALES SESSION, NEEDS TO BE CLEANED UP************************************/
.sales-session-main {
  display: flex;
  flex-direction: row;
  min-width: 100%;
}
.sales-session-sidemenu {
  position: fixed;
  height: 100%;
  width: 200px;
}

.sales-session-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 200px;
  padding: 15px;
  margin-top: 50px;
  width: 85%;
}
.sales-session-add-name {
  border-bottom: 2px #f7f7f7 solid;
  margin-top: 20px;
}
.sales-session-form-top {
  margin-top: 20px;
}
.sales-session-drop-down {
  z-index: 5;
}

.sales-session-remove-room-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  padding: 5px;
  font-size: 20px;
}
.allocate-staff-table {
  padding: 5px 5px 0px 0px;
}

.allocate-staff-footer {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
}
.save-sales-session-button {
  background-color: blue; /* Red */
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
}
.delete-sales-session-button {
  background-color: #ef3c52; /* Red */
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
}
.sales-session-add-input-text {
  white-space: nowrap;
  overflow: hidden;
  text-align: left !important;
  font-weight: normal;
  font-size: 15px;
}

.sales-session-select-office {
  white-space: nowrap;
  overflow: hidden;
  text-align: left !important;
  font-weight: normal;
  font-size: 15px;
  z-index: 2;
}
.sales-session-select-office-z {
  z-index: 3;
}

.sales-session-other-meeting-types {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 85%;

  min-height: 250px;
  padding-right: 15px;
  position: fixed;
}
.other-meeting-types-info {
  display: flex;
  flex-direction: column;
  padding-right: 60px;
  padding-left: 45px;
  padding-top: 15px;
  background-color: #f7f7f7;
  flex: 1;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 200px;
}
.other-meeting-types-edit {
  padding-top: 30px;
  padding-left: 30px;

  background-color: #ffffff;
  flex: 5;
  margin-left: 0px;
  margin-right: 0px;

  min-height: 200px;
}
.algorithm-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
}
.algorithm-footer {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  background-color: #f7f7f7;
}
.other-meeting-types-edit-header {
  display: flex;
  flex-direction: row;
  border-bottom: 2px #f7f7f7 solid;
  padding-bottom: 15px;
}
.other-meeting-types-edit-body {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}
.other-meeting-meeting-type-box {
  display: flex;
  background-color: #ef3c52;
  border: none;
  color: white;
  padding: 9px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  margin-left: 0;
  flex: 3;
  border-radius: 4px;
  max-width: 400px;
  outline: none;
}
.other-meeting-meeting-type-box:hover {
  background-color: #a60c46;
}
.burgundy-background {
  background-color: #a60c46 !important;
}
.showroom-structure-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
  background-color: #f7f7f7;
  margin-right: -15px !important;
  min-height: 200px;
  position: fixed;
  padding: 15px;
  padding-left: 25px;
}

.showroom-structure-header-rooms {
  display: flex;
  flex-direction: column;
  padding-right: 60px;
  padding-left: 20px;
  flex: 3;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 200px;
}

.showroom-structure-header-meeting-possibilities {
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  flex: 3;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 200px;
}
.showroom-structure-header-duration {
  display: flex;
  flex-direction: column;
  padding-right: 50px;

  flex: 2;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 200px;
}
.showroom-structure-header-joker {
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  flex: 2;
  margin-left: 0px;
  margin-right: 15px;
  min-height: 200px;
}

.showroom-structure-room-selector {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  width: 90%;
  margin-left: -15px;
  margin-right: -15px !important;
  margin-top: 200px;
  position: fixed;
  padding: 15px;
  padding-left: 25px;
}
.showroom-structure-room-selector-headers {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
  border-bottom: 2px #f7f7f7 solid;

  margin-left: -15px;

  padding: 15px;
  padding-left: 25px;
}
.showroom-structure-room-selector-room {
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
  margin-left: -15px;

  padding: 15px;
  padding-right: 20px;
}
.showroom-structure-name-room {
  display: flex;
  flex-direction: column;
  flex: 3;
  text-align: left;
  font-weight: bold;
}
.showroom-structure-example {
  font-weight: normal !important;
  font-style: italic !important;
  color: #c7c7c7 !important;
}
.showroom-structure-simultaneous-meeting-colums {
  display: flex;
  flex-direction: column;
  flex: 2;
  text-align: left;
  font-weight: bold;
}
.showroom-structure-standardized-time {
  display: flex;
  flex-direction: column;
  flex: 2;
  text-align: left;
  font-weight: bold;
}

.other-meeting-types-name {
  display: flex;
  flex-direction: column;
  flex: 3;
  text-align: left;
  font-weight: bold;

  padding-left: 15px;
}
.no-left-padding {
  padding-left: 0px !important;
}

.other-meeting-types-duration {
  display: flex;
  flex-direction: column;
  flex: 2;
  text-align: left;
  font-weight: bold;
  max-width: 200px;
  padding-left: 15px;
}

.other-meeting-types-label-colour {
  display: flex;
  flex-direction: column;
  flex: 2;
  text-align: left;
  font-weight: bold;
  max-width: 200px;
  padding-left: 15px;
}

.showroom-structure-add-room {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: #ffffff;
  width: 90%;
  margin-left: -15px;
  margin-right: -15px !important;
  padding: 15px;
  padding-left: 25px;
  border-top: 2px #f7f7f7 solid;
}
.sales-session-algoritm-info {
  display: flex;
  flex-direction: column;
  padding-right: 60px;
  padding-left: 45px;
  padding-top: 15px;
  background-color: #f7f7f7;
  flex: 2;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 200px;

  position: relative;
}
.algorithm-information-text {
  padding-top: 15px;
  font-weight: normal;
}

.timezone-picker {
  display: inline-block;
  font: 13px sans-serif;
  position: relative;
  width: 100%;
}

.timezone-picker-textfield input {
  width: 100% !important;
  padding: 9px 12px;
  font: inherit;
  box-sizing: border-box;
  outline: 0;
  background: #fff;
  border: 1px solid #e6ebec;
  border-radius: 2px;
}

.timezone-picker-list {
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #e6ebec;
  margin-top: -1px;
  border-radius: 0 0 3px 3px;
  display: none;
}

.timezone-picker-list-rel {
  position: relative;
}

.timezone-picker-list-abs {
  position: absolute;
  background-color: white;
  z-index: 2000;
  opacity: 0.99;
}

.timezone-picker-list-item {
  color: #444444;
  padding: 5px 12px;
}

.timezone-picker-list-item-active {
  background: #ececec;
  cursor: pointer;
}

.timezone-picker-open .timezone-picker-list {
  display: block;
}

.timezone-picker-selected .timezone-picker-textfield input {
  color: #474747;
}
.arrow-icons {
  font-size: 16px;
  max-width: 20px;
  max-height: 20px;
}
